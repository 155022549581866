import ValueButton from 'components/widgets/edito/ValueButton';
import { CMSCallToActionType, CmsImageType } from 'utils/cms/types';

type Props = {
  value_buttons: {
    background_color: 'blue' | 'gold';
    cta: CMSCallToActionType;
    foreground_image: CmsImageType;
    title: string;
  }[];
};

const ValueButtonGroup = ({ value_buttons: valueButtons }: Props) => (
  <div className="flex flex-col lg:flex-row gap-3 p-4 justify-center max-w-1200 m-auto">
    {valueButtons.map((valueButton) => (
      <ValueButton
        key={valueButton.cta.link?.url}
        className={valueButtons.length === 2 ? 'basis-1/2' : 'basis-1/3'}
        foregroundImage={valueButton.foreground_image.url}
        link={valueButton.cta.link}
        title={valueButton.title}
        variant={valueButton.background_color}
      />
    ))}
  </div>
);

export default ValueButtonGroup;
