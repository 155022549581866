/* eslint-disable @next/next/no-img-element */
import clx from 'classnames';
import { useInView } from 'framer-motion';
import Link from 'next/link';
import { useRef } from 'react';
import { CMSLinkType } from 'utils/cms/types';

export type ValueButtonVariant = 'blue' | 'gold';

type ValueButtonProps = {
  title: string;
  link?: CMSLinkType;
  variant: ValueButtonVariant;
  foregroundImage: string;
  className?: string;
};

const ValueButton = ({ title, link, foregroundImage, variant = 'blue', className }: ValueButtonProps) => {
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });

  return (
    <Link
      ref={ref}
      className={clx(
        'relative min-h-[164px] p-2 flex flex-1 clickable duration-even-slower ease-out transition-all group hover:rounded',
        {
          'bg-gradient-to-r from-[#AD7C3F] to-[#FFD789]': variant === 'gold',
          'bg-gradient-to-r from-[#00567A] to-[#1093D1]': variant === 'blue',
        },
        className,
      )}
      href={link?.url || ''}
    >
      <div className={clx('absolute inset-0 overflow-hidden flex items-center justify-center')}>
        <img
          alt=""
          className={clx(
            'h-96 group-hover:h-88',
            'opacity-20 group-hover:opacity-5',
            ' duration-even-slower ease-out transition-all',
            inView ? 'scale-100' : 'scale-110',
          )}
          src="/logos/swirl.svg"
        />
      </div>

      <div className="absolute left-0 h-fit w-1/2 justify-end flex flex-col py-2 pl-3 top-0 bottom-0 m-auto">
        <p
          className={clx(
            'title-3 text-white text-shadow-lg shadow-black/60 z-3',
            'duration-even-slower ease-out transition-all group-hover:-translate-x-1',
            inView ? 'translate-x-0' : '-translate-x-3',
          )}
        >
          {title}
        </p>
      </div>

      <img
        alt=""
        className={clx(
          'absolute bottom-0 right-0 h-full z-1 origin-bottom-right ',
          'duration-even-slower ease-out transition-all scale-130 group-hover:scale-105 ',
          inView ? 'scale-110' : 'scale-100',
        )}
        src={foregroundImage}
      />
    </Link>
  );
};

export default ValueButton;
